<template>
  <div class="detail_wrap">
    <div class="title">
      <img src="../../assets/image/star/star-logo.png" alt="" />
    </div>
    <div class="title_desc">明星学员</div>
    <div class="content_wrap">
      <div class="content_left">
        <div class="active_star">
          <div class="star_bg">
            <transition enter-active-class="rotateIn">
              <div class="active_star_avtar" v-show="changeStar">
                <img :src="currentStar.pictureUrl" alt="" />
              </div>
            </transition>
          </div>
          <div class="star_line">
            <img src="../../assets/image/star/star_line.png" alt="" />
          </div>
        </div>
        <div class="star_list">
          <transition-group
            enter-active-class="fadeInUp"
            leave-active-class="bounceOut"
            ><div
              class="star_item"
              v-for="item in starList"
              :key="item.id"
              @click="itemClick(item)"
            >
              <img :src="item.pictureUrl" alt="" />
              <div class="mask"></div></div
          ></transition-group>
        </div>
      </div>
      <div class="content_right">
        <div class="star_name">{{ currentStar.authorName }}</div>
        <div class="star_intro">
          <span>{{ currentStar.majorName }}</span>
          <!-- <span>珠宝设计</span> -->
        </div>
        <div class="star_intro">
          <span>任职公司：{{ currentStar.serviceCompany }}</span>
          <span>任职岗位：{{ currentStar.jobName }}</span>
        </div>
        <div class="star_desc">
          <!-- <div class="line"></div> -->
          <div class="desc_wrap" v-html="currentStar.personalIntro"></div>
        </div>
      </div>
    </div>
    <bottom-point class="bottom_wrap"></bottom-point>
  </div>
</template>

<script>
import bottomPoint from "@/components/BottomPoint";
import { queryForStarsList, queryForStarDetail } from "@/apis/index";
require("vue2-animate/dist/vue2-animate.min.css");
export default {
  components: { bottomPoint },
  data() {
    return {
      starList: [],
      currentStar: {},
      currentItem: {},
      changeStar: true,
      initIndex: 0,
    };
  },
  mounted() {
    const index = parseInt(this.$route.query.index) || 0;
    this.initIndex = index >= 10 ? index - 10 : index;
    this.queryForStarList();
  },
  methods: {
    itemClick(item) {
      const temp = this.currentItem;
      // 设置当前的放大明星
      this.currentItem = item;
      this.getStarDetail();
      // 删除数组内点击的明星
      const index = this.starList.indexOf(item);
      this.starList.splice(index, 1);
      // console.log("00", this.starList);
      // 把原来的加入最后
      setTimeout(() => {
        this.starList.push(temp);
      }, 1000);
      // console.log("11", this.starList);
    },
    queryForStarList() {
      queryForStarsList().then((res) => {
        if (res.code === 200) {
          this.starList = res.data;
          this.currentItem = this.starList[this.initIndex];
          this.starList.splice(this.initIndex, 1);
          // console.log("000", this.starList.length);
          this.getStarDetail();
        }
      });
    },
    getStarDetail() {
      queryForStarDetail({ id: this.currentItem.id }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.currentStar = res.data;
          this.changeStar = false;
          setTimeout(() => {
            this.changeStar = true;
          }, 100);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail_wrap {
  margin: auto;
  width: 1760px;
  height: 975px;
  background: url("../../assets/image/course/detail/course_detail_bg.png")
    center center no-repeat;
  background-size: cover;
  position: relative;

  .title {
    width: 1248px;
    height: 65px;
    margin-left: 31px;
    padding-top: 35px;
  }

  .title_desc {
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
    padding-left: 29px;
    padding-top: 15px;
  }

  .content_wrap {
    width: 1644px;
    height: 800px;
    margin-left: 51px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .content_left {
      .active_star {
        width: 313px;
        height: 243px;
        position: relative;
        padding-bottom: 19px;

        .star_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 243px;
          height: 243px;
          background: url("../../assets/image/star/star_avtar.png") center
            center no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .active_star_avtar {
            width: 173px;
            height: 173px;
            border-radius: 50%;
            // background-color: red;
            overflow: hidden;
          }
        }

        .star_line {
          position: absolute;
          right: 0;
          top: 40px;
          width: 116px;
          height: 125px;
        }
      }

      .star_list {
        width: 243px;
        height: 560px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        // background-color: #8afff2;

        .star_item {
          flex-shrink: 0;
          width: 107px;
          height: 107px;
          border-radius: 50%;
          margin-bottom: 40px;
          // background-color: aqua;
          overflow: hidden;
          position: relative;

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(18, 21, 26, 0.52);
          }
        }
      }
    }

    .content_right {
      width: 1331px;
      padding-top: 40px;
      .star_name {
        width: fit-content;
        margin-left: 8px;
        background: #ffffff;
        border-radius: 8px 0px 8px 0px;
        font-size: 40px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #12151a;
        line-height: 39px;
        padding: 3px 13px;
        overflow: hidden;
      }

      .star_intro {
        margin-top: 13px;
        margin-left: 8px;

        span {
          display: inline-block;
          width: fit-content;
          margin-right: 13px;
          background: #ffffff;
          border-radius: 5px 0px 5px 0px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #12151a;
          line-height: 20px;
          padding: 3px 13px;
          overflow: hidden;
        }
      }

      .star_desc {
        margin-top: 28px;
        width: 100%;
        height: 571px;
        background: url("../../assets/image/star/star_desc_bg.png") center
          center no-repeat;
        background-size: 100% 100%;
        position: relative;

        .desc_wrap {
          position: absolute;
          width: 1248px;
          height: 505px;
          left: 35px;
          top: 31px;
          font-size: 21px;
          color: #ffffff;
          line-height: 43px;
          white-space: pre-wrap;
          overflow: auto;

          .desc_item {
            width: 100%;
            font-size: 21px;
            color: #ffffff;
            line-height: 43px;
            padding-top: 31px;

            .item_title {
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
            }

            .item_desc {
              font-family: PingFangSC-light, PingFang SC;
              font-weight: 300;
            }

            &:first-child {
              padding-top: 0px;
            }
          }
        }

        .line {
          position: absolute;
          width: 4px;
          height: 400px;
          right: 29px;
          top: 41px;
          background-color: #8afff2;
          border-radius: 3px;
        }
      }
    }
  }

  .bottom_wrap {
    position: absolute;
    width: 1680px;
    bottom: 16px;
    left: 40px;
  }
}
</style>
